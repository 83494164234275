/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import './SwipeableList.css';
import Button from '../Button';

/**
 * https://malcoded.com/posts/react-swipeable-list/
 */
class SwipeableListItem extends React.Component {
  // DOM Refs
  // listElement;
  // wrapper;
  // backgroundSwipeLeft;
  // backgroundSwipeRight;

  // Drag & Drop
  // dragStartX = 0;
  // left = 0;
  // dragged = false;

  // FPS Limit
  // startTime;
  // fpsInterval = 1000 / 60;

  constructor(props) {
    super(props);

    this.state = {
      gridTemplateColumns: '',
      maxColumns: 0,
    };
    this.listElement = null;
    this.wrapper = null;
    this.backgroundSwipeLeft = null;
    this.backgroundSwipeRight = null;

    this.dragStartX = 0;
    this.left = 0;
    this.dragged = false;

    this.startTime = null;
    this.fpsInterval = 1000 / 60;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onDragStartMouse = this.onDragStartMouse.bind(this);
    this.onDragStartTouch = this.onDragStartTouch.bind(this);
    this.onDragEndMouse = this.onDragEndMouse.bind(this);
    this.onDragEndTouch = this.onDragEndTouch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.onClick = this.onClick.bind(this);

    this.onSwipedLeft = this.onSwipedLeft.bind(this);
    this.onSwipedRight = this.onSwipedRight.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onDragEndMouse);
    window.addEventListener('touchend', this.onDragEndTouch);
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onDragEndMouse);
    window.removeEventListener('touchend', this.onDragEndTouch);
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchmove', this.onTouchMove);
  }

  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener('mousemove', this.onMouseMove);
  }

  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener('touchmove', this.onTouchMove);
  }

  onDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.className = this.listElement.className.replace(/BouncingListItem/, 'ListItem');
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  onDragEndMouse() {
    window.removeEventListener('mousemove', this.onMouseMove);
    this.onDragEnd();
  }

  onDragEndTouch() {
    window.removeEventListener('touchmove', this.onTouchMove);
    this.onDragEnd();
  }

  onDragEnd() {
    if (!this.dragged) {
      return;
    }
    this.dragged = false;

    const {
      threshold = 0.6,
      swipeLeftEnabled,
      swipeRightEnabled,
    } = this.props;

    if (Math.abs(this.left) > this.listElement.offsetWidth * threshold) {
      // if drag exceeds threshold for a swipe event
      if (this.left < 0 && swipeLeftEnabled) {
        // if we're swiping left
        this.left = -this.listElement.offsetWidth * 2;
        this.transitionOut();
        this.onSwipedLeft();
      } else if (this.left > 0 && swipeRightEnabled) {
        // if we're swiping right
        this.left = this.listElement.offsetWidth * 2;
        this.transitionOut();
        this.onSwipedRight();
      } else {
        // if we exceed threshold but swiping is disable, we need to reset (bounce back)
        this.left = 0;
      }
    } else {
      // we need to reset (bounce back)
      this.left = 0;
    }
    // swipe did not happen, bounce back
    this.listElement.className = this.listElement.className.replace(/ListItem/, 'BouncingListItem');
    this.listElement.style.transform = `translateX(${this.left}px)`;
  }

  onMouseMove(evt) {
    const left = evt.clientX - this.dragStartX;
    this.left = left;
  }

  onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    this.left = left;
  }

  onClick() {
    const { onClick = () => undefined } = this.props;
    onClick();
  }

  onSwipedLeft() {
    const { onSwipeLeft = () => undefined } = this.props;
    onSwipeLeft();
  }

  onSwipedRight() {
    const { onSwipeRight = () => undefined } = this.props;
    onSwipeRight();
  }

  resize() {
    const {
      children,
      swipeRightEnabled,
      onSwipeRight,
      swipeLeftEnabled,
      onSwipeLeft,
      display,
    } = this.props;
    const numChildren = Array.isArray(children) ? children.filter((c) => typeof c === 'object').length : 1; // check for typeof so we don't count 'false' as an object
    const stateUpdate = {
      // default to swipe instead of buttons
      swipeRightBtn: false,
      swipeLeftBtn: false,
    };
    if (window.innerWidth > 800) {
      // we're going to add buttons
      if (swipeRightEnabled && onSwipeRight) {
        stateUpdate.swipeRightBtn = true;
      }
      if (swipeLeftEnabled && onSwipeLeft) {
        stateUpdate.swipeLeftBtn = true;
      }
    }
    if (window.innerWidth < window.innerHeight) {
      // portrait
      stateUpdate.maxColumns = display && display.minColumns
        ? Math.min(display.minColumns, numChildren)
        : numChildren;
      stateUpdate.gridTemplateColumns = display && display.portrait
        ? display.portrait.slice(0, stateUpdate.maxColumns).join(' ')
        : Array(stateUpdate.maxColumns).fill().map(() => '1fr').join(' ');
    } else {
      // landscape
      stateUpdate.maxColumns = display && display.minColumns
        ? Math.max(display.minColumns, numChildren)
        : numChildren;
      stateUpdate.gridTemplateColumns = display && display.landscape
        ? display.landscape.slice(0, stateUpdate.maxColumns).join(' ')
        : Array(stateUpdate.maxColumns).fill().map(() => '1fr').join(' ');
    }
    if (stateUpdate.swipeLeftBtn || stateUpdate.swipeRightBtn) {
      stateUpdate.gridTemplateColumns += ' 2fr';
    } else if (stateUpdate.swipeLeftBtn || stateUpdate.swipeRightBtn) {
      stateUpdate.gridTemplateColumns += ' 1fr';
    }
    this.setState(stateUpdate);
  }

  updatePosition() {
    if (this.dragged) {
      requestAnimationFrame(this.updatePosition);
    }

    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      const opacity = (Math.abs(this.left) / 100).toFixed(2);
      if (this.backgroundSwipeLeft && this.left < 0) {
        // can only swipe left if there's a backgroundSwipeLeft
        this.listElement.style.transform = `translateX(${this.left}px)`;
        if (this.backgroundSwipeRight) {
          this.backgroundSwipeRight.style.opacity = '0'; // hide the other div
        }
        if (opacity.toString() !== this.backgroundSwipeLeft.style.opacity) {
          this.backgroundSwipeLeft.style.opacity = Math.min(opacity, 1).toString();
        }
      } else if (this.backgroundSwipeRight && this.left > 0) {
        // can only swipe right if there's a backgroundSwipeRight
        this.listElement.style.transform = `translateX(${this.left}px)`;
        if (this.backgroundSwipeLeft) {
          this.backgroundSwipeLeft.style.opacity = '0'; // hide the other div
        }
        if (opacity.toString() !== this.backgroundSwipeRight.style.opacity) {
          this.backgroundSwipeRight.style.opacity = Math.min(opacity, 1).toString();
        }
      }

      this.startTime = Date.now();
    }
  }

  transitionOut() {
    // this.wrapper.style.maxHeight = 0; // hide the row to remove it
    this.wrapper.style.maxHeight = '0px';
  }

  render() {
    const {
      backgroundSwipeLeft,
      swipeLeftEnabled,
      swipeLeftButtonText,

      backgroundSwipeRight,
      swipeRightEnabled,
      swipeRightButtonText,

      onSwipeLeft,
      onSwipeRight,
      children,
    } = this.props;
    const {
      gridTemplateColumns,
      maxColumns,
      swipeRightBtn,
      swipeLeftBtn,
    } = this.state;
    return (
      <>
        <div className="Wrapper" ref={(div) => { this.wrapper = div; }}>
          {backgroundSwipeLeft && (
            <div ref={(div) => { this.backgroundSwipeLeft = div; }} className={swipeLeftEnabled ? 'BackgroundSwipeLeft' : 'BackgroundSwipeLeftDisabled'}>
              {backgroundSwipeLeft}
            </div>
          )}
          {backgroundSwipeRight && (
            <div ref={(div) => { this.backgroundSwipeRight = div; }} className={swipeRightEnabled ? 'BackgroundSwipeRight' : 'BackgroundSwipeRightDisabled'}>
              {backgroundSwipeRight}
            </div>
          )}
          <div
            // eslint-disable-next-line jsx-a11y/aria-role
            role="ListItem"
            ref={(div) => { this.listElement = div; }}
            onMouseDown={window.innerWidth <= 800 ? this.onDragStartMouse : () => {}}
            onTouchStart={window.innerWidth <= 800 ? this.onDragStartTouch : () => {}}
            onClick={this.onClick}
            className="ListItem"
            style={{
              gridTemplateColumns,
            }}
          >
            {Array.isArray(children) ? children.slice(0, maxColumns) : children}
            {(swipeRightEnabled || swipeLeftEnabled) && (
              <div className="col-ml-auto btn-group">
                {swipeRightBtn && (
                  <Button
                    btn="btn"
                    onClick={onSwipeRight}
                    text={swipeRightButtonText}
                    disableDuration={1000}
                  />
                )}
                {swipeLeftBtn && (
                  <Button
                    btn="btn"
                    onClick={onSwipeLeft}
                    text={swipeLeftButtonText}
                    disableDuration={1000}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

class SwipeableList extends Component {
  render() {
    const { children, display } = this.props;

    const childrenWithProps = React.Children.map(children, (child) => {
      if (!display) {
        return child;
      }
      return React.cloneElement(child, { display });
    });

    return <div className="List">{childrenWithProps}</div>;
  }
}

export { SwipeableList, SwipeableListItem };
