import React, { useState, useEffect } from 'react';

export default ({
  confirm,
  disableDuration,
  isEnabled,
  onClick,
  tooltip,
  style = {},
  className = 'btn',
  text,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [confirmed, setConfirmed] = useState(!confirm);

  useEffect(() => {
    if (buttonEnabled) {
      return () => undefined;
    }
    const timer = setTimeout(() => {
      setButtonEnabled(true);
    }, disableDuration);
    return () => clearTimeout(timer);
  }, [buttonEnabled, disableDuration]);

  if (confirm) {
    return (
      <div className="columns">
        <div className="column col-auto col-ml-auto">
          <div className="input-group">
            {confirm && (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="form-switch">
              <input type="checkbox" onChange={() => setConfirmed(!confirmed)} />
              <i className="form-icon" />
            </label>
            )}
            {confirmed && (typeof isEnabled === 'undefined' || isEnabled)
              ? (
                <button
                  type="button"
                  onClick={(event) => {
                    if (!confirmed) {
                      return;
                    }
                    onClick(event);
                    setButtonEnabled(false);
                  }}
                  data-tooltip={tooltip}
                  style={style}
                  className={(className || 'btn')
                          + (confirmed ? '' : ' disabled')
                          + (buttonEnabled ? '' : ' loading')
                          + (tooltip ? ' tooltip tooltip-bottom' : '')}
                >
                  {text}
                </button>
              )
              : (
                <button
                  type="button"
                  disabled
                  style={style}
                  className={`${className} disabled`}
                >
                  {text}
                </button>
              )}
          </div>
        </div>
      </div>
    );
  }

  if (typeof isEnabled !== 'undefined' && !isEnabled) {
    return (
      <button
        type="button"
        disabled
        style={style}
        className={`${className} disabled`}
      >
        {text}
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        if (!confirmed) {
          return;
        }
        onClick(event);
        setButtonEnabled(false);
      }}
      data-tooltip={tooltip}
      style={style}
      className={(className)
                + (confirmed ? '' : ' disabled')
                + (buttonEnabled ? '' : ' loading')
                + (tooltip ? ' tooltip tooltip-bottom' : '')}
    >
      {text}
    </button>
  );
};
