/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import * as timeago from 'timeago.js';
import Button from './Button';


import { SwipeableList, SwipeableListItem } from './swipeable/SwipeableList';

export default ({
  orderNumber,
  createdDate,
  orderFor,
  orderStatus,
  items,
  onUpdate,
  onItemUpdate,
}) => {
  const formattedDate = timeago.format(createdDate);

  return (
    <>
      <div className="card text-left animate__animated animate__zoomIn">
        <div className="card-header animate__animated animate__fadeIn animate__delay-1s">
          <div className="card-title h5">
            <span>{orderNumber}</span>
            <span className="label float-right">To Go</span>
          </div>
          <div className="card-subtitle">
            <span>{orderFor}</span>
            <time className="float-right">{formattedDate}</time>
          </div>
        </div>
        <div className="card-body">

          <SwipeableList
            display={{
            }}
          >
            {Object
              .entries(items)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([name, item]) => (
                <SwipeableListItem
                  key={name}
                  swipeLeftEnabled={false}
                  swipeRightEnabled={false}
                          // on click
                  onClick={() => {
                    if (onItemUpdate) {
                      if (item.status === 'completed') {
                        onItemUpdate(name, 'pending');
                      } else {
                        onItemUpdate(name, 'completed');
                      }
                    }
                  }}
                >
                  {item.status === 'completed' ? (
                    <>
                      <div className="tile animate__animated animate__fadeIn animate__delay-1s">
                        <div className="tile-icon">
                          <div className="item-quantity">
                            <s>{item.quantity}</s>
                          </div>
                        </div>
                        <div className="tile-content">
                          <div className="tile-title">
                            <div className="item-name c-hand">
                              <s>{name}</s>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tile animate__animated animate__fadeIn animate__delay-1s">
                        <div className="tile-icon">
                          <div className="item-quantity">
                            {item.quantity}
                          </div>
                        </div>
                        <div className="tile-content">
                          <div className="tile-title">
                            <div className="item-name c-hand">{name}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </SwipeableListItem>
              ))}
          </SwipeableList>

        </div>
        <div className="card-footer">
          {orderStatus === 'pending' && (
            <Button
              className="btn btn-primary btn-block animate__animated animate__fadeIn animate__delay-1s"
              text="Done"
              onClick={() => { onUpdate('completed'); }}
              disableDuration={5000}
            />
          )}
          {orderStatus === 'completed' && (
            <Button
              className="btn btn-primary btn-block animate__animated animate__fadeIn animate__delay-1s"
              text="Restart"
              onClick={() => { onUpdate('pending'); }}
              disableDuration={5000}
            />
          )}
        </div>
      </div>
    </>
  );
};
