import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';

import { FirebaseContext } from './utils/firebase';
import 'firebase/firestore';

import Nav from './utils/Nav';

import './App.css';
import {
  CompletedOrdersPage,
  PendingOrdersPage,
  OrdersTodayPage,
} from './OrdersPage';
import ScreensPage from './ScreensPage';
import HomePage from './HomePage';

const ProtectedRoute = ({ hasAccess, children, ...rest }) => {
  if (!hasAccess) {
    return <Redirect to="/" />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      {children}
    </Route>
  );
};

const Error = ({
  message,
}) => (
  <>
    <h2>{message}</h2>
  </>
);

export default () => {
  const firebase = useContext(FirebaseContext);
  if (!firebase) {
    return <Error message="Could not load firebase." />;
  }

  // auth listener
  const [auth, setAuth] = useState({
    finished: false,
    user: null,
  });
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setAuth({
        finished: true,
        user,
      });
    });
    return () => { console.log('onAuthStateChanged unsub'); unsubscribe(); };
  }, [firebase]);

  // user listener
  const [dbUser, setDbUser] = useState(null);
  useEffect(() => {
    if (!auth.user) {
      return () => undefined;
    }
    const unsubscribe = firebase.firestore().collection('users').doc(auth.user.uid)
      .onSnapshot(setDbUser);
    return () => { console.log('dbUser unsubscribe'); unsubscribe(); };
  }, [firebase, auth]);

  /**
   * ===============
   * Create new user
   * ===============
   */
  useEffect(() => {
    if (!auth.user
      || !dbUser
      || dbUser.exists) {
      return;
    }
    console.log('creating new user:', auth.user);
    // user has auth'd but dbUser doesn't exist. time to create a new user
    const userRef = firebase.firestore().collection('users').doc(auth.user.uid);
    userRef.set({
      name: auth.user.displayName,
      email: auth.user.email,
      households: [],
    });
  }, [firebase, auth, dbUser]);

  const hasLoggedIn = !auth.finished || (auth.user && auth.user.uid);
  return (
    <div className="App">
      <BrowserRouter>
        <Nav
          firebaseAuth={firebase.auth()}
          firestore={firebase.firestore()}
          dbUser={dbUser}
          auth={auth}
        />
        <Switch>
          <ProtectedRoute
            exact
            path="/pendingOrders"
            hasAccess={hasLoggedIn}
          >
            <PendingOrdersPage
              firestore={firebase.firestore()}
              auth={auth}
            />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/completeOrders"
            hasAccess={hasLoggedIn}
          >
            <CompletedOrdersPage
              firestore={firebase.firestore()}
              auth={auth}
              status="completed"
            />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/allOrders"
            hasAccess={hasLoggedIn}
          >
            <OrdersTodayPage
              firestore={firebase.firestore()}
              auth={auth}
            />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/screens"
            hasAccess={hasLoggedIn}
          >
            <ScreensPage />
          </ProtectedRoute>
          <Route exact path="/">
            <HomePage
              firebaseAuth={firebase.auth()}
              auth={auth}
            />
          </Route>
        </Switch>
      </BrowserRouter>
      <br />
      <footer>
        <br />
      </footer>
    </div>
  );
};
