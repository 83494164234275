/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Order from './utils/Order';

import './OrdersPage.css';
import { SwipeableList, SwipeableListItem } from './utils/swipeable/SwipeableList';

const OrdersPage = ({
  firestore,
  auth,
  status = undefined,
  since,
}) => {
  const [orders, setOrders] = useState(null);
  useEffect(() => {
    if (!firestore
      || !since
      || !auth || !auth.user || !auth.finished) {
      return () => undefined;
    }
    console.log('orders queried');
    let unsub;
    if (status) {
      unsub = firestore.collection('orders')
        .where('status', '==', status)
        // .where('createdDate', '>', since)
        .orderBy('createdDate', 'asc')
        .limit(24)
        .onSnapshot((ss) => {
          setOrders(ss.docs);
        });
    } else {
      unsub = firestore.collection('orders')
        // .where('createdDate', '>', since)
        .orderBy('createdDate', 'asc')
        .limit(24)
        .onSnapshot((ss) => {
          setOrders(ss.docs);
        });
    }
    return () => { console.log('orders unsub'); unsub(); };
  }, [firestore, auth, status, since]);

  const changeOrderStatus = async (orderId, newStatus) => {
    const orderRef = firestore.collection('orders').doc(orderId);
    await firestore.runTransaction(async (trans) => {
      const order = (await trans.get(orderRef)).data();
      order.status = newStatus;
      await trans.set(orderRef, order);
    });
  };

  const changeItemState = async (orderId, itemName, newStatus) => {
    const orderRef = firestore.collection('orders').doc(orderId);
    await firestore.runTransaction(async (trans) => {
      const order = (await trans.get(orderRef)).data();
      order.items[itemName].status = newStatus;
      await trans.set(orderRef, order);
    });
  };

  return (
    <>
      <div className="columns col-mx-auto">
        <div className="column col-9">
          <div className="columns">
            {orders && orders.map((order) => (
              <div key={`order-${order.id}`} className="column col-3 col-xl-4 col-lg-6 col-sm-12">
                <Order
                  orderNumber={order.id}
                  createdDate={order.data().createdDate.toDate().toLocaleString()}
                  orderFor={order.data().orderFor}
                  orderStatus={order.data().status}
                  items={order.data().items}
                  onUpdate={(newStatus) => {
                    changeOrderStatus(order.id, newStatus);
                  }}
                  onItemUpdate={(itemName, newStatus) => {
                    changeItemState(order.id, itemName, newStatus);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="column col-3">
          <div className="panel text-left animate__animated animate__fadeIn animate__delay-1s" id="summary-panel">
            <div className="panel-header animate__animated animate__fadeIn animate__delay-1s">
              <div className="panel-title text-bold">Item Summary</div>
            </div>
            <div className="panel-body">
              <SwipeableList
                display={{}}
              >
                {orders && Object
                  .entries(
                    orders.reduce((acc, order) => {
                      Object
                        .entries(order.data().items)
                        .filter(([, item]) => item.status === 'pending')
                        .forEach(([name, item]) => {
                          acc[name] = (acc[name] || 0) + item.quantity;
                        });
                      return acc;
                    }, {}),
                  )
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([name, quantity]) => (
                    <SwipeableListItem
                      key={name}
                      swipeLeftEnabled={false}
                      swipeRightEnabled={false}
                      // on click
                      onClick={() => {
                        console.log('clicked');
                      }}
                    >
                      <div className="tile animate__animated animate__fadeIn animate__delay-1s">
                        <div className="tile-icon">
                          {quantity}
                        </div>
                        <div className="tile-content">
                          <div className="tile-title">{name}</div>
                        </div>
                      </div>
                    </SwipeableListItem>
                  ))}
              </SwipeableList>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const today = new Date();
today.setHours(0, 0, 0);

const PendingOrdersPage = ({
  firestore,
  auth,
}) => OrdersPage({
  firestore,
  auth,
  status: 'pending',
  since: today,
});

const CompletedOrdersPage = ({
  firestore,
  auth,
}) => OrdersPage({
  firestore,
  auth,
  status: 'completed',
  since: today,
});

const OrdersTodayPage = ({
  firestore,
  auth,
}) => OrdersPage({
  firestore,
  auth,
  since: today,
});

export {
  PendingOrdersPage,
  CompletedOrdersPage,
  OrdersTodayPage,
};
