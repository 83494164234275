import React, { createContext } from 'react';
import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

const FirebaseContext = createContext(null);
export { FirebaseContext };

export default ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({
      apiKey: 'AIzaSyBnTHBCOZqa4tLCEmnJHsF3uy844BWQ7sw',
      authDomain: 'tqkds-39c24.firebaseapp.com',
      databaseURL: 'https://tqkds-39c24.firebaseio.com',
      projectId: 'tqkds-39c24',
      storageBucket: 'tqkds-39c24.appspot.com',
      messagingSenderId: '164923344562',
      appId: '1:164923344562:web:f8623d8f07a7b09ccdabab',
      measurementId: 'G-F9TYKPZS9R',
    });
  }
  return (
    <FirebaseContext.Provider value={app}>
      { children }
    </FirebaseContext.Provider>
  );
};
