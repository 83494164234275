import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import 'firebase/auth';

/**
 * props = {
 *   auth: {},
 *   dbUser: snapshot,
 *   dbUserHousehold: snapshot,
 *   onHouseholdChange: fn(selectedHouseholdId),
 *   accessToHousehold: boolean,
 * }
 */
export default (props) => {
  const {
    auth,
  } = props;
  const location = useLocation();

  return (
    <header className="navbar">
      <section className="navbar-section">
        {auth.user
          ? (
            <>
              <div className="dropdown text-left">
                <div className="btn btn-link dropdown-toggle">
                  <figure className="avatar">
                    <img src="https://picturepan2.github.io/spectre/img/avatar-1.png" alt="..." />
                    <i className="avatar-presence online" />
                  </figure>
                  <i className="icon icon-caret" />
                </div>
                <ul className="menu">
                  <li className="menu-item"><a href="#dropdowns">Settings</a></li>
                  <li className="menu-item"><a href="#dropdowns">Analytics</a></li>
                </ul>
              </div>
            </>
          )
          : (
            <></>
          )}
      </section>
      <section className="navbar-center">
        <a href="/" className="navbar-brand mr-2 text-bold hide-sm">Kitchen Hēro</a>
      </section>
      <section className="navbar-section">
        {auth.user && (
        <>
          <Link to="/pendingOrders" className={`btn ${location.pathname === '/pendingOrders' ? 'btn-active' : 'btn-link'}`}>
            <span>Pending</span>
          </Link>
          <Link to="/completeOrders" className={`btn ${location.pathname === '/completeOrders' ? 'btn-active' : 'btn-link'}`}>
            <span>Complete</span>
          </Link>
          <Link to="/allOrders" className={`btn ${location.pathname === '/allOrders' ? 'btn-active' : 'btn-link'}`}>
            <span>All</span>
          </Link>
        </>
        )}
      </section>
    </header>
  );
};
