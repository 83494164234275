/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

const AuthedHomePage = ({
  firebaseAuth,
}) => {
  const history = useHistory();

  const signOut = async () => {
    await firebaseAuth.signOut();
    history.push('/');
  };
  return (
    <>
      <h1>Welcome</h1>
      <br />
      <span>
        <strong>Welcome!</strong>
        <br />
        <strong>Let&apos;s get you setup</strong>
        <br />
        <span>
          To get started, add staff@restauranthero.app to your Shopify store as
          Staff Account
        </span>
        <br />
        <span>We&apos;ll notify you once we successfully configured your store.</span>
        <br />
      </span>
      <br />
      {/* eslint-disable-next-line react/button-has-type */}
      <button onClick={signOut}>Log out</button>
    </>
  );
};

const UnauthedHomePage = ({
  firebaseAuth,
}) => {
  const signIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    firebaseAuth.signInWithRedirect(provider);
  };

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const signInEmail = () => {
    if (!email.match(/^[a-zA-Z0-9-._]+@[a-zA-Z0-9-.]+$/g)) {
      setEmailError('Not a valid e-mail address');
      return;
    }
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setPasswordError('Incorrect email/password combination');
        console.error(error);
      });
  };
  const signInEmailButtonRef = React.createRef();

  return (
    <>
      <h1>Sign In</h1>
      <h2>Sign in with</h2>
      {/* eslint-disable-next-line react/button-has-type */}
      <button onClick={signIn}>G Sign in</button>
      <input
        className={emailError ? 'form-input is-error' : 'form-input'}
        type="text"
        placeholder="Email"
        maxLength="50"
        value={email}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && signInEmailButtonRef) {
            signInEmailButtonRef.current.click();
          }
        }}
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailError(null);
        }}
      />
      <input
        className={passwordError ? 'form-input is-error' : 'form-input'}
        type="password"
        placeholder="Password"
        maxLength="50"
        value={password}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && signInEmailButtonRef) {
            signInEmailButtonRef.current.click();
          }
        }}
        onChange={(event) => {
          setPassword(event.target.value);
          setPasswordError(null);
        }}
      />
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        ref={signInEmailButtonRef}
        onClick={signInEmail}
      >
        E-Mail Sign in
      </button>
    </>
  );
};

export default ({
  firebaseAuth,
  auth,
}) => {
  if (auth.finished && auth.user) {
    // user signed in
    return (
      <AuthedHomePage
        firebaseAuth={firebaseAuth}
      />
    );
  }
  return (
    <UnauthedHomePage
      firebaseAuth={firebaseAuth}
    />
  );
};
