/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default () => {
  const [screenName, setScreenName] = useState('');
  const [screenNameError, setScreenNameError] = useState(null);
  const [screenAdded, setScreenAdded] = useState(false);
  const [screenLocation, setScreenLocation] = useState('');

  const createNewScreen = () => {
    console.log(`Creating screen: ${screenName}`);
    setScreenAdded(true);
  };

  if (screenAdded) {
    return (
      <>
        <h1>All done!</h1>
        <span>
          Congratulations! New screen license has been activated.
          On your kitchen display tablet, navigate to:
        </span>
        <span><Link href="https://kds.restuaranthero.app">https://kds.restuaranthero.app</Link></span>
        <span>At the prompt, enter the following:</span>
        <span>Screen ID</span>
        <h2>710385</h2>
        <span>Key</span>
        <h2>09914</h2>
      </>
    );
  }

  return (
    <>
      <div className="form-group">
        <select
          className="form-select"
          style={{ color: 'black' }}
          value={screenLocation}
          onChange={(event) => {
            setScreenLocation(event.target.value);
          }}
        >
          <option value="">Shopify Location</option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
        </select>
      </div>
      <input
        className={screenNameError ? 'form-input is-error' : 'form-input'}
        type="text"
        placeholder="Screen Name"
        maxLength="50"
        value={screenName}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            createNewScreen();
          }
        }}
        onChange={(event) => {
          setScreenName(event.target.value);
          setScreenNameError(null);
        }}
      />
    </>
  );
};
